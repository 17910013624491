import React, { useState, useEffect, useMemo } from 'react';
import { useAuth } from '../context/AuthContext';
import axiosInstance from '../api/axiosInstance';
import logo from '../SC_logo.svg';
import './style/Core.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const DownloadData = () => {
    const [loading, setLoading] = useState(false);
    const [loadingSeasons, setLoadingSeasons] = useState(false);
    const [error, setError] = useState(null);
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [filteredSeasons, setFilteredSeasons] = useState(null);
    const [selectedAreaId, setSelectedAreaId] = useState(null);
    const [selectedCompetitionId, setSelectedCompetitionId] = useState(null);
    const [selectedSeasons, setSelectedSeasons] = useState([]);
    const [currentSelections, setCurrentSelections] = useState([]);

    const topAreas = useMemo(() => ['England', 'Spain', 'Italy', 'Germany', 'France', 'United States', 'Mexico', 'Argentina', 'Brazil', 'Europe', 'World'], []);

    useEffect(() => {
        const fetchFilteredSeasons = async () => {
            setLoadingSeasons(true);
            try {
                const response = await axiosInstance.get('/stats/filteredseasons/', { timeout: 600000 });
                const data = response.data.sort((a, b) => {
                    let indexA = topAreas.indexOf(a.name);
                    let indexB = topAreas.indexOf(b.name);
                    indexA = indexA === -1 ? Number.MAX_SAFE_INTEGER : indexA; // Put unknown areas at the end
                    indexB = indexB === -1 ? Number.MAX_SAFE_INTEGER : indexB;
                    return indexA - indexB || a.name.localeCompare(b.name);
                }).map(area => {
                    area.competitions.sort((a, b) => {
                        const genderOrder = (a.gender === 'male' ? -1 : 1) - (b.gender === 'male' ? -1 : 1);
                        const typeOrder = (a.type === 'club' ? -1 : 1) - (b.type === 'club' ? -1 : 1);
                        const divisionOrder = (a.divisionLevel === 0 ? Number.MAX_SAFE_INTEGER : a.divisionLevel) - (b.divisionLevel === 0 ? Number.MAX_SAFE_INTEGER : b.divisionLevel);
                        const formatOrder = (a.format === 'Domestic league' ? -1 : 1) - (b.format === 'Domestic league' ? -1 : 1);
                        const categoryOrder = (a.category === 'default' ? -1 : 1) - (b.category === 'default' ? -1 : 1);
                        
                        if (genderOrder !== 0) return genderOrder;
                        if (typeOrder !== 0) return typeOrder;
                        if (divisionOrder !== 0) return divisionOrder;
                        if (formatOrder !== 0) return formatOrder;
                        if (categoryOrder !== 0) return categoryOrder;
                        return a.name.localeCompare(b.name);
                    });
                    area.competitions.forEach(comp => {
                        comp.seasons.sort((a, b) => b.startDate.localeCompare(a.startDate));
                    });
                    return area;
                });
                setFilteredSeasons(data);
            } catch (err) {
                if (err.response && err.response.status === 403) {
                    toast.error('Session expired. Please log in again.');
                    logout();
                    navigate('/login');
                } else {
                    toast.error('An error occurred while fetching data.');
                    setError(err.message || "An error occurred while fetching data.");
                }
            } finally {
                setLoadingSeasons(false);
            }
        };

        fetchFilteredSeasons();
    }, [logout, navigate, topAreas]);

    const TypingText = ({ text }) => {
        const [displayedText, setDisplayedText] = useState('');
        const [index, setIndex] = useState(0);
    
        useEffect(() => {
            const intervalId = setInterval(() => {
                if (index < text.length) {
                    setDisplayedText(displayedText => displayedText + text.charAt(index));
                    setIndex(index + 1);
                } else {
                    setDisplayedText('');
                    setIndex(0);
                }
            }, 100);
    
            return () => clearInterval(intervalId);  
        }, [text, index]);
    
        return <div className="typing-text-dark">{displayedText}</div>;
    };
    
    const handleAreaChange = event => {
        setSelectedAreaId(event.target.value);
        setSelectedCompetitionId('');
    };

    const handleCompetitionChange = event => {
        setSelectedCompetitionId(event.target.value);
    };

    const handleSeasonChange = event => {
        setCurrentSelections(Array.from(event.target.selectedOptions, option => option.value));
    };

    const handleAddSelectedSeasons = () => {
        const newSelections = currentSelections.map(seasonId => {
            return {
                areaId: selectedAreaId,
                competitionId: selectedCompetitionId,
                seasonId: seasonId
            };
        }).filter(selection => !selectedSeasons.some(season => season.seasonId === selection.seasonId));
        setSelectedSeasons(selectedSeasons.concat(newSelections));
        setCurrentSelections([]);
    };

    const renderSeasonName = selection => {
        const area = filteredSeasons.find(area => area.id.toString() === selection.areaId);
        const competition = area?.competitions.find(comp => comp.wyId.toString() === selection.competitionId);
        const season = competition?.seasons.find(season => season.wyId.toString() === selection.seasonId);
        return `${area?.name} - ${competition?.name} (${season?.name})`;
    };

    function downloadFile(url, filename) {
        axiosInstance.get(url, { responseType: 'blob', timeout: 600000 })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                toast.error('An error occurred while downloading the file.');
                setLoading(false);
            });
    }
    const handleUpdateEvents = () => {
        setLoading(true);
        // Send a POST request to update the stats
        const url = '/seasons/update-season-events/'
        // Get the seasonId from selected seasons
        const season_ids = selectedSeasons.map(s => s.seasonId);

        axiosInstance.post(url, {seasons: season_ids}, { timeout: 600000 })
            .then(response => {
                setLoading(false);
                toast.success('Events updated successfully.');
            }
            )
            .catch(error => {
                toast.error('An error occurred while updating events.');
                console.log(error);
                setLoading(false);
            });
    };
    
    const handleDownloadStats = () => {
        setLoading(true);
        const queryParams = selectedSeasons.map(s => `season=${encodeURIComponent(s.seasonId)}`).join('&');
        const url = '/stats/seasonmatchstatsraw/?' + queryParams;
        axiosInstance.get(url, { timeout: 600000 })
            .then(response => {
                setLoading(false);
                downloadFile(response.data, 'stats.json');
            })
            .catch(error => {
                toast.error('An error occurred while downloading stats.');
                setLoading(false);
            });
    };

    const handleDownloadEvents = () => {
        setLoading(true);
        const queryParams = selectedSeasons.map(s => `season=${encodeURIComponent(s.seasonId)}`).join('&');
        const url = '/stats/seasoneventsdownload/?' + queryParams;
        downloadFile(url, 'season_events.zip');
    };

    if (loadingSeasons) {
        return (
            <div style={{ textAlign: 'center', padding: '20px' }}>
                <img src={logo} alt="Logo" className="rotating-logo" />
                <TypingText text="L o a d i n g . . ." />
            </div>
        );
    }

    if (error) return <p>An error occurred: {error}</p>;

    return (
        <div className="downloadData-container">
            {filteredSeasons ? (
                <div>
                    <select className="downloadData-select" onChange={handleAreaChange} value={selectedAreaId || ''}>
                        <option value="">Select Area</option>
                        {filteredSeasons.map(area => (
                            <option key={area.id} value={area.id}>{area.name}</option>
                        ))}
                    </select>
    
                    {selectedAreaId && (
                        <select className="downloadData-select" onChange={handleCompetitionChange} value={selectedCompetitionId || ''}>
                            <option value="">Select Competition</option>
                            {filteredSeasons.find(area => area.id.toString() === selectedAreaId)?.competitions.map(competition => (
                                <option key={competition.wyId} value={competition.wyId}>{competition.name} ({
                                    competition.gender === 'male' ? 'M' : 'F'}{competition.divisionLevel})</option>
                            ))}
                        </select>
                    )}
    
                    {selectedCompetitionId && (
                        <>
                            <select className="downloadData-select" multiple onChange={handleSeasonChange} value={currentSelections || []}>
                                {filteredSeasons.find(area => area.id.toString() === selectedAreaId)?.competitions.find(comp => comp.wyId.toString() === selectedCompetitionId)?.seasons.map(season => (
                                    <option key={season.wyId} value={season.wyId}>{season.name}</option>
                                ))}
                            </select>
                            <button className="downloadData-button" onClick={handleAddSelectedSeasons}>Add to Selected Seasons</button>
                        </>
                    )}
    
                    {selectedSeasons.length > 0 && (
                        <>
                            <div>
                                <h2 className="downloadData-header">Selected Seasons:</h2>
                                <ul className="downloadData-list">
                                    {selectedSeasons.map((selection, index) => (
                                        <li className="downloadData-item" key={index}>{renderSeasonName(selection)}</li>
                                    ))}
                                </ul>
                            </div>
                            <div>
                                <button className="downloadData-button" onClick={handleDownloadStats}>Download Stats</button>
                                <button className="downloadData-button" onClick={handleDownloadEvents}>Download Events</button>
                                <button className="downloadData-button" onClick={handleUpdateEvents}>Update Events</button>
                            </div>
                        </>
                    )}
                    {loading && (
                        <div style={{ textAlign: 'center', padding: '20px' }}>
                            <TypingText text="D o w n l o a d i n g . . ." />
                        </div>
                    )}
                </div>
            ) : (
                <p className="downloadData-message">No filter data available.</p>
            )}
        </div>
    );
};

export default DownloadData;
